import React, { PropsWithChildren, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Button, Icon, Fade } from '@column/column-ui-kit';
import { Headline } from '~/styles';

interface ModalButtonProps {
  isLoading?: boolean;
  onClick: () => void;
  text: string;
}

enum ModalSize {
  FitContent = 'fit-content',
  Small = 'small',
  Medium = 'medium',
}

export interface ModalProps {
  headline?: string;
  icon?: React.ReactNode;
  onClose?: () => void;
  open: boolean;
  primaryButton?: ModalButtonProps;
  secondaryButton?: ModalButtonProps;
  size?: ModalSize;
}

const Backdrop = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(0 0 0 / 40%);
`;

const BaseModal = styled.div`
  background-color: ${({ theme }) => theme.background};
  box-shadow:
    0 0 0 1px ${({ theme }) => theme.secondary.blendToBackground(1100, 150)},
    ${({ theme }) => theme.style.floatingShadowXL};
  border-radius: 12px;
  width: var(--modal-width);
  height: var(--modal-height);
  position: relative;
  z-index: 2;
  padding: 24px;

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      box-shadow:
        0 0 0 1px ${theme.secondary.blendToBackground(150)},
        ${({}) => theme.style.floatingShadowXL};
    `}
`;

const Buttons = styled.div<{ grow?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 24px -24px -24px -24px;
  padding: 24px;
  border-radius: 0 0 12px 12px;
  background-color: ${({ theme }) => theme.secondary.blendToBackground(25)};
  border-top: 1px solid ${({ theme }) => theme.secondary.blendToBackground(150)};

  ${({ theme }) =>
    theme.id !== 'default' &&
    css`
      background-color: ${theme.body};
      border-top: 1px solid ${theme.secondary.blendToBackground(50)};
    `}

  ${({ grow }) =>
    grow &&
    css`
      * {
        flex-grow: 1;
      }
    `}
`;

const CircleInner = styled.div`
  width: 68px;
  height: 68px;
  border-radius: 50%;
  margin: 0 auto 24px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.primary.blendToBackground(1000, 100)};

  svg {
    --icon-size: 30px;
    --icon-color: ${({ theme }) => theme.primary.background};
  }
`;

const Circle = styled(CircleInner)`
  background-color: ${({ theme }) => theme.primary.blendToBackground(1000, 100)};

  svg {
    --icon-color: ${({ theme }) => theme.primary.background};
  }
`;

const Close = styled.button`
  appearance: none;
  outline: none;
  border: none;
  background: none;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  right: 8px;
  top: 8px;
  padding: 0;
  margin: 0;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  z-index: 1;
  transition: background-color 0.2s;

  svg {
    --icon-size: 20px;
    --icon-color: ${({ theme }) => theme.secondary.blendToBackground(750)};

    path {
      transition: stroke 0.2s;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.secondary.blendToBackground(1000, 100)};
    svg {
      --icon-color: ${({ theme }) => theme.secondary.blendToBackground(1000)};
    }
  }
`;

const Scroll = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  overflow-y: auto;
  overscroll-behavior: contain;
  display: grid;
  place-items: center;
`;

const StyledButton = styled(Button)`
  flex: 1;
`;

const StyledHeadline = styled(Headline)`
  margin-bottom: 8px;
`;

const Text = styled.div`
  display: grid;
  grid-gap: 8px;
  margin-bottom: 24px;
  text-align: center;
`;

const Wrapper = styled(Fade)<{ size?: ModalSize }>`
  --animation-enter-y: 0;
  --animation-exit-y: 0;

  --modal-width: ${({ size }) => {
    switch (size) {
      case ModalSize.Small:
        return '330px';
      case ModalSize.Medium:
        return '550px';
      case ModalSize.FitContent:
      default:
        return 'fit-contetn';
    }
  }};
  --modal-height: fit-content;
  z-index: 14;
  position: fixed;
  inset: 0;
  overflow: hidden;
`;

export const Modal: React.FC<PropsWithChildren<ModalProps>> & { Size: typeof ModalSize } = ({
  children,
  headline,
  icon,
  onClose,
  open,
  primaryButton,
  secondaryButton,
  size,
}) => {
  useEffect(() => {
    const keyDownListener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose?.();
      }
    };
    window.addEventListener('keydown', keyDownListener);
    return () => window.removeEventListener('keydown', keyDownListener);
  }, []);

  return (
    <Wrapper size={size} show={open} timeoutEnter={0}>
      <Backdrop onClick={onClose} />
      <Scroll>
        <BaseModal onClick={(e: React.MouseEvent) => e.stopPropagation()}>
          <Close onClick={onClose}>
            <Icon.Close />
          </Close>
          <Circle>{icon ? icon : <Icon.CircleInfo />}</Circle>
          {headline && (
            <Text>
              <StyledHeadline fullWidth size="small">
                {headline}
              </StyledHeadline>
            </Text>
          )}
          {children}
          {(primaryButton || secondaryButton) && (
            <Buttons grow>
              {secondaryButton && (
                <StyledButton
                  variant={'secondary'}
                  onClick={secondaryButton.onClick}
                  isLoading={secondaryButton.isLoading}
                >
                  {secondaryButton.text}
                </StyledButton>
              )}
              {primaryButton && (
                <StyledButton variant={'primary'} onClick={primaryButton.onClick} isLoading={primaryButton.isLoading}>
                  {primaryButton.text}
                </StyledButton>
              )}
            </Buttons>
          )}
        </BaseModal>
      </Scroll>
    </Wrapper>
  );
};
Modal.Size = ModalSize;
